import { Function as FunctionIcon } from '@blueprintjs/icons';
import {
  AccessibilityIcon,
  AppsIcon,
  BellIcon,
  BroadcastIcon,
  BugIcon,
  ClockIcon,
  CodeIcon,
  CreditCardIcon,
  DependabotIcon,
  GlobeIcon,
  HomeFillIcon,
  HomeIcon,
  KeyIcon,
  LogIcon,
  MailIcon,
  OrganizationIcon,
  PaintbrushIcon,
  PeopleIcon,
  PersonIcon,
  PlusIcon,
  ReplyIcon,
  ReportIcon,
  ShieldLockIcon,
  WorkflowIcon,
} from '@primer/octicons-react';
import { IconButton, Spinner } from '@primer/react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import type { MouseEventHandler } from 'react';
import { Suspense, useCallback, useEffect, useState } from 'react';

import type { Group, SidebarContentProps } from '@/components/SidebarContent';
import { SidebarContent } from '@/components/SidebarContent';

export interface SidebarNavProps extends Omit<SidebarContentProps, 'groups'> {}

const AddQuestButton = () => {
  // https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
  // This sucks because it means there's no way to detect errors or possible intermediate redirects.

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const router = useRouter();
  const destPath = '/quests/new';
  const currentPath = `${pathname}${searchParams.size ? `?${searchParams.toString()}` : ''}`;

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading && currentPath === destPath) {
      setLoading(false);
    }
  }, [isLoading, setLoading, currentPath, destPath]);

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      e.preventDefault();
      e.stopPropagation();
      if (!isLoading) {
        setLoading(true);
        void router.push(destPath);
      }
    },
    [router, isLoading, destPath]
  );

  return (
    <IconButton
      as="button"
      icon={isLoading ? () => <Spinner size="small" /> : PlusIcon}
      size="small"
      aria-label="Create Quest"
      onClick={onClick}
    />
  );
};

const SidebarData = [
  {
    key: 'main',
    items: [
      {
        icon: <HomeIcon />,
        activeIcon: <HomeFillIcon />,
        title: 'Home',
        href: '/',
        match: /^\/?$/,
      },
      {
        icon: <DependabotIcon />,
        title: 'Quests',
        href: '/quests',
        match: /^\/quests?($|\/)/,
        activeAction: (
          <Suspense fallback={null}>
            <AddQuestButton />
          </Suspense>
        ),
      },
      { icon: <PersonIcon />, title: 'Public profile', href: '/settings/profile', dummy: true },
      { icon: <PeopleIcon />, title: 'Sharing', href: '/settings/sharing', dummy: true },
      { icon: <PaintbrushIcon />, href: '/settings/appearance', title: 'Appearance', dummy: true },
      { icon: <AccessibilityIcon />, href: '/settings/accessibility', title: 'Accessibility', dummy: true },
      { icon: <BellIcon />, href: '/settings/notifications', title: 'Notifications', dummy: true },
    ],
  },
  {
    key: 'debug',
    title: 'DEBUG MODE',
    items: [
      { icon: <BugIcon />, title: 'Debug', href: '/dbg' },
      { icon: <WorkflowIcon />, title: 'Chains', href: '/dbg/chains', match: /^\/dbg\/chains?($|\/)/ },
      { icon: <BellIcon />, title: 'Timers', href: '/dbg/timers', match: /^\/dbg\/timers?($|\/)/ },
      { icon: <FunctionIcon />, title: 'Tools', href: '/dbg/tools', match: /^\/dbg\/tools?($|\/)/ },
    ],
    role: 'omni',
  },
  {
    key: 'rules',
    title: 'Rules & Limits',
    items: [
      { icon: <BroadcastIcon />, title: 'Triggers', href: '/settings/triggers', dummy: true },
      { icon: <ClockIcon />, title: 'Schedules', href: '/settings/schedules', dummy: true },
      { icon: <AppsIcon />, title: 'Integrations', href: '/settings/integrations', dummy: true },
      { icon: <ReplyIcon />, title: 'Saved replies', href: '/settings/replies', dummy: true },
    ],
    role: 'omni',
  },
  {
    key: 'access',
    title: 'Access',
    items: [
      {
        key: 'billing',
        icon: <CreditCardIcon />,
        title: 'Billing and plans',
        items: [
          { title: 'Plans and usage', href: '/settings/billing/summary', dummy: true },
          { title: 'Spending limits', href: '/settings/billing/spending_limit', dummy: true },
          { title: 'Payment information', href: '/settings/billing/payment_information', dummy: true },
        ],
      },
      { icon: <OrganizationIcon />, title: 'Organizations', href: '/settings/organizations', dummy: true },
      { icon: <MailIcon />, title: 'Emails', href: '/settings/emails', dummy: true },
      { icon: <ShieldLockIcon />, title: 'Password and authentication', href: '/settings/security', dummy: true },
      { icon: <BroadcastIcon />, title: 'Sessions', href: '/settings/sessions', dummy: true },
      { icon: <KeyIcon />, title: 'SSH and GPG keys', href: '/settings/keys', dummy: true },
      { icon: <GlobeIcon />, title: 'Enterprises', href: '/settings/enterprises', dummy: true },
      {
        key: 'moderation',
        icon: <ReportIcon />,
        title: 'Moderation',
        items: [
          { title: 'Blocked users', href: '/settings/blocked_users', dummy: true },
          { title: 'Interaction limits', href: '/settings/interaction_limits', dummy: true },
          { title: 'Code review limits', href: '/settings/code_review_limits', dummy: true },
        ],
      },
    ],
    role: 'omni',
  },
  {
    key: 'security',
    title: 'Security',
    items: [
      {
        icon: <ShieldLockIcon />,
        title: 'Code security and analysis',
        href: '/settings/security_analysis',
        dummy: true,
      },
    ],
    role: 'omni',
  },
  {
    key: 'integrations',
    title: 'Integrations',
    items: [
      { icon: <AppsIcon />, title: 'Applications', href: '/settings/installations', dummy: true },
      { icon: <ClockIcon />, title: 'Scheduled reminders', href: '/settings/reminders', dummy: true },
    ],
    role: 'omni',
  },
  {
    key: 'archives',
    title: 'Archives',
    items: [
      { icon: <LogIcon />, title: 'Security log', href: '/settings/security-log', dummy: true },
      { icon: <LogIcon />, title: 'Compliance log', href: '/settings/compliance-log', dummy: true },
    ],
    role: 'omni',
  },
  {
    key: 'developer',
    items: [{ icon: <CodeIcon />, title: 'Developer settings', href: '/settings/apps', dummy: true }],
    role: 'omni',
  },
] satisfies readonly Group[];

export const SidebarNav = (props: SidebarNavProps) => {
  return <SidebarContent groups={SidebarData} {...props} />;
};

export default SidebarNav;
