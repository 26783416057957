import type React from 'react';

import { NavList, NavListGroup, NavListItem, NavListSubNav } from '@/lib/primer/NavList';

export type RoleName =
  // Multi-tenant sysadmin user.
  | 'omni'
  // Tenant admin
  | 'admin'
  // Tenant user
  | 'user';

export const RoleNamme = ['omni', 'admin', 'user'] as const;

export function parseRoleName(value?: unknown): RoleName | null;
export function parseRoleName<T = 'user'>(value?: unknown, missing?: T): RoleName | T | null;
export function parseRoleName<T = 'user'>(value?: unknown, missing: T = 'user' as T): RoleName | T | null {
  return !value ? missing : typeof value === 'string' && RoleNamme.includes(value) ? value : null;
}

export function parseRoleNames(value?: unknown): RoleName[] | null;
export function parseRoleNames<T = RoleName[]>(value?: unknown, missing?: T): RoleName[] | T | null;
export function parseRoleNames<T = RoleName[]>(value?: unknown, missing: T = [] as T): RoleName[] | T | null {
  if (!value) {
    return missing;
  }
  const values = Array.isArray(value) ? [...value] : typeof value === 'string' ? value.split(',') : [value];
  return values.every(v => typeof v === 'string' && RoleNamme.includes(v)) ? (values as RoleName[]) : null;
}

export interface Permittable {
  /**
   * The roles able to view this thing. Any one of the list.
   */
  role?: RoleName | readonly RoleName[];
}

const isPermitted = (roles: readonly RoleName[], thing: Permittable) => {
  const needRoleIn = typeof thing.role === 'string' ? [thing.role] : thing.role;
  return needRoleIn == null || needRoleIn.some(role => roles.includes(role));
};

export interface Group extends Permittable {
  title?: string;
  key: string;
  items: readonly (Item | SubGroup)[];
}

export interface SubGroup extends Permittable {
  title: string;
  key: string;
  icon: React.ReactNode;
  items: readonly Item[];
}

export interface Item extends Permittable {
  title: string;
  key?: string;
  icon?: React.ReactNode;
  activeIcon?: React.ReactNode;
  activeAction?: React.ReactNode;
  href: string;
  match?: RegExp;

  /**
   * If this is a dummy URL, it goes nowhere.
   */
  dummy?: true;
}

export interface SidebarContentProps extends Omit<React.ComponentProps<typeof NavList>, 'children'> {
  currentPage?: string;
  groups: readonly Group[];
  /**
   * Currently-authorized roles.
   */
  roles?: readonly RoleName[];
}

// Dummy links do not attempt to navigate.
const onClickDoNothing: React.MouseEventHandler<HTMLAnchorElement> = e => void e.preventDefault();

// https://github.com/primer/react/issues/4427
// https://github.com/primer/react/issues/4367
// -> https://github.com/primer/react/pull/4392
// There's a bug in NavItem via ActionList.LinkItem.
// The "sx" props are not propagating correctly, because _PrivateItemWrapper does not accept "sx" props
// from the parent "LiBox" in the ActionList.Item component.
// So although NavItem is passing aria-current="page" correctly, and that does trigger the correct sx style on the
// LiBox, it then gets clobbered by the _PrivateItemWrapper which is not applying the correctly-merged prop.
// Alternatively, maybe NavList.getSubnavStyles could potentially replace "null" with "undefined" to avoid unsetting?
// And/or the contents are overridden by: https://github.com/primer/react/blob/3ec63098e159d5b9fb14e044e5134972ca8dbfb8/packages/react/src/ActionList/Item.tsx#L322
export const SidebarContent = ({ currentPage, groups, roles = [], ...props }: SidebarContentProps) => {
  const item = (item: Item, i: number) => {
    if (!isPermitted(roles, item)) {
      return null;
    }
    const { key, title, match, role, icon, activeIcon, activeAction: trailingVisual, dummy, ...el } = item;
    const isActive =
      currentPage != null &&
      (match
        ? match.test(currentPage)
        : currentPage === el.href || (currentPage !== '/' && el.href.startsWith(currentPage)));
    return (
      <NavListItem
        key={key ?? i}
        {...(isActive && { 'aria-current': 'page' })}
        onClick={dummy ? onClickDoNothing : undefined}
        leadingVisual={isActive && activeIcon ? activeIcon : icon}
        {...(isActive && trailingVisual && { trailingVisual })}
        {...el}
      >
        {title}
      </NavListItem>
    );
  };

  const subGroup = (sg: SubGroup) => {
    if (!isPermitted(roles, sg)) {
      return null;
    }
    const { key, title, items, icon } = sg;
    const els = items.map(item).filter(n => n != null);
    if (!els.length) {
      return null;
    }
    return (
      <NavListItem key={key} leadingVisual={icon}>
        {title}
        <NavListSubNav>{els}</NavListSubNav>
      </NavListItem>
    );
  };

  const group = (g: Group) => {
    if (!isPermitted(roles, g)) {
      return null;
    }
    const { key, title, items } = g;
    const els = items.map((el, i) => ('items' in el ? subGroup(el) : item(el, i))).filter(n => n != null);
    if (!els.length) {
      return null;
    }
    return (
      <NavListGroup key={key} {...(title && { title })}>
        {els}
      </NavListGroup>
    );
  };

  return <NavList {...props}>{groups.map(group)}</NavList>;
};

export default SidebarContent;
