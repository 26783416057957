'use client';

import { usePathname } from 'next/navigation';

import { useSession } from '@/auth/client';
import { parseRoleNames } from '@/components/SidebarContent';
import { useDevFlag } from '@/flag';

import type { SidebarNavProps } from './SidebarNav';
import { SidebarNav } from './SidebarNav';

export interface SidebarCurrentNavProps extends Omit<SidebarNavProps, 'currentPage'> {}

export const SidebarCurrentNav = (props: SidebarCurrentNavProps) => {
  const pathName = usePathname();
  const session = useSession();
  // FIXME: add a claim
  void session.data?.user;

  const roles = useDevFlag('ui.sidebar.roles', value => parseRoleNames(value)) ?? ['user'];

  return <SidebarNav currentPage={pathName} roles={roles} {...props} />;
};

export default function DefaultSidebar() {
  return <SidebarCurrentNav />;
}
